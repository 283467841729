@import "../../global.scss";

.resume {
  font-size: 14px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: whitesmoke;

  @include mobile{
    width: 100%;
  }

  &.active {
    background-color: $mainColor;
    color: white;
  }
}
